body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  color: #5f5f61;
}

#works-grid,
#gallery,
#contacts,
#about{
  min-height: calc(100vh - 13em);
}

#playground {
  width: 900px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

#header{
  width: 100%;
  padding: 2em 0;
  display: flex;
  justify-content: space-between;
}

#header #menu{
  font-size: 14px;
  text-align: right;
  line-height: 20px;
  width: 298px;
}

a:link,
a:hover,
a:active,
a:visited{
  text-decoration: none;
  color: inherit;
}

#header #menu a:link,
#header #menu a:hover,
#header #menu a:active,
#header #menu a:visited{
  text-decoration: none;
  color: inherit;
  padding: 0 0.5em;
}

#header #menu a:hover,
#header #menu a.selected,
.custom1711:hover{
  color: #e31e24;
  font-weight: bold;
}

#header #mainmenu,
#header #submenu{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/*#header #submenu a:link,
#header #submenu a:hover,
#header #submenu a:active,
#header #submenu a:visited{
  padding: 0 0.5em;
}*/

#header #menu #submenu a:hover,
.custom1711:hover,
#header #menu #submenu a.selected{
  font-weight: normal;
}

#header #menu #mainmenu a:first-child,
#header #menu #submenu a:first-child{
  padding-left: 0;
}

#header #menu #mainmenu a:last-child,
#header #menu #submenu a:last-child{
  padding-right: 0;
}

#footer{
  font-size: 11px;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
}

#works-grid{
  width: 100%;
  display: flex;
  gap: 18px 18px;
  flex-wrap: wrap;
  align-content: flex-start;
}

#works-grid .work-card{
  width: 288px;
  height: 160px;
  box-shadow: 4px 4px 5px #B0B0B0;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

#works-grid .work-card:hover > .work-card-details{
  visibility: visible;
  opacity: 1;
}

#works-grid .work-card .work-card-details{
  width: 100%;
  height: 100%;
  line-height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  /*visibility: hidden;*/
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

#works-grid .work-card .work-card-details div:last-child{
  padding-top: 0.5em;
}

#gallery > #image{
  width: 100%;
  height: 472px;
  background-repeat: no-repeat;
  background-position: center center;
}

#gallery > #gallery-navigation{
  font-size: 12px;
  text-align: center;
  padding-top: 0.4em;
}

#gallery > #gallery-navigation span{
  font-size: 18px;
  padding: 0 0.5em;
}

#gallery > #image{
  /*display: flex;*/
  position: relative;
}

#gallery > #image > div{
  width: 55%;
  height: 100%;
  cursor: pointer;
  left: -5%;
  position: absolute;
  text-align: left;
  line-height: 472px;
  font-size: 1.5em;
}

#gallery > #image > div.r{
  left: auto;
  right: -5%;
  text-align: right;
}

#gallery > #image > div:hover{
  color: #e31e24;
}

#gallery > #gallery-navigation span{
  cursor: pointer;
}

#gallery > #gallery-navigation span:hover{
  color: #e31e24;
}

#gallery > #gallery-description{
  display: flex;
  justify-content: space-between;
  padding-top: 1em;
  font-size: 12px;
  color: rgb(95, 95, 97);
  align-items: flex-end;
  min-height: 80px;
  box-sizing: border-box;
}

#gallery > #gallery-description div:first-child{
  width: 470px;
  text-align: justify;
  transition: color 0.5s ease-in-out;
}

#gallery > #gallery-description div:last-child{
  width: 235px;
}

#gallery > #gallery-description .hidden{
  color: rgb(224, 224, 224);
}

#gallery > #gallery-description .hidden:hover{
  color: inherit;
}

#contacts{
  display: flex;
  justify-content: space-between;
}

#contacts > div{
  width: 45%;
}

#contacts > div:first-child > div {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2.5em;
}

#contacts > div:first-child > div > div:first-child{
  width: 25%;
  font-weight: bold;
  font-size: 14px;
}

#contacts > div:first-child > div > div:last-child{
  width: 75%;
  font-weight: bold;
  font-size: 14px;
}

#contacts input{
  outline: none;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #5f5f61;
  font-size: 14px;
  margin-top: -0.2em;
}

#contacts textarea{
  outline: none;
  width: 100%;
  margin-top: 1em;
  height: 8em;
}

#contacts > div:first-child > .textarea{
  flex-wrap: wrap;
  padding-bottom: 1.5em;
}

#contacts > div:first-child > .textarea > div:first-child,
#contacts > div:first-child > .textarea > div:last-child{
  width: 100%;
}

#contacts > div:last-child > div:last-child{
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding-top: 1.5em;
}

#about{
  display: flex;
  justify-content: space-between;
}

#about div:first-child{
  width: 20%;
  display: flex;
  justify-content: space-between;
  height: auto;
  max-height: 234px;
}

#about #bio{
  width: 75%;
  font-size: 14px;
  text-align: justify;
  color: #4e565a;
}

#about > div:first-child > img{
  display: block;
  width: auto;
  height: auto;
  max-width: 120px;
  max-height: 234px;
}

#about div:first-child div:last-child{
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 236px;
  justify-content: space-between;
}

