@media only screen and (max-width: 950px) {
  #works-grid,
  #gallery,
  #contacts,
  #about{
    min-height: auto;
  }

  #playground {
    width: calc(100% - 2em);
    height: auto;
    padding: 0 1em;
  }

  #header{
    width: 100%;
    padding: 0.5em 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  #logo{
    width: 100%;
  }

  #header #menu{
    width: 100%;
    padding: 1em 0 0.5em 0;
  }

  #header #menu div{
    display: flex;
    justify-content: space-between;
  }

  #header #menu a:link,
  #header #menu a:hover,
  #header #menu a:active,
  #header #menu a:visited{
    padding: none;
  }

  #header #submenu a:link,
  #header #submenu a:hover,
  #header #submenu a:active,
  #header #submenu a:visited{
    padding: none;
  }

  #footer{
    width: 100%;
    text-align: center;
  }

  #works-grid{
    width: 100%;
    display: flex;
    gap: 18px 0;
    justify-content: center;
  }

  #works-grid .work-card{
    width: 90vw;
    height: auto;
    background-size: cover;
    box-sizing: border-box;
  }

  #works-grid .work-card .work-card-details{
    width: 100%;
    height: auto;
    line-height: 100%;
    background-color: transparent;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s linear;
    text-align: center;
    color: white;
    display: block;
    padding-top: 44.55%;
  }

  #works-grid .work-card .work-card-details div{
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 0.5em;
  }

  #works-grid .work-card .work-card-details div:last-child{
    padding-top: 0.3em;
    padding-bottom: 0.5em;
  }

  /*
  GALLERY
  */

  #gallery.mobile img{
    width: 100%;
    height: auto;
    margin-bottom: 0.5em;
  }

  #gallery.mobile .l-description{
    font-size: 12px;
    color: rgb(95, 95, 97);
    text-align: justify;
    margin-top: none;
    padding: 0;
  }

  #gallery.mobile .r-description{
    font-size: 12px;
    color: rgb(95, 95, 97);
    padding: 0 0 0.5em 0;
  }

  #gallery.mobile > div{
    margin-top: 1.5em;
  }

  #gallery.mobile > div:first-child{
    margin-top: 0;
  }

  /*
    CONTACTS
  */

  #contacts{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
  }

  #contacts > div{
    width: 100%;
  }

  #contacts > div:first-child{
    margin-top: 2em;
  }

  #contacts input{
    padding: 0;
  }

  #contacts textarea{
    padding: 0;
  }

  #contacts > div:last-child > div:last-child{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding-top: 1.5em;
  }

  /* 
    ABOUT
  */

  #about{
    flex-wrap: wrap;
  }

  #about div:first-child{
    width: 40%;
    min-width: 170px;
    display: flex;
    justify-content: space-between;
    height: auto;
    max-height: 234px;
  }

  #about #bio{
    width: 100%;
    margin-top: 2em;
  }

  #about > div:first-child > img{
    display: block;
    width: auto;
    height: auto;
    max-width: 120px;
    max-height: 234px;
  }

  #about div:first-child div:last-child{
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 236px;
    justify-content: space-between;
  }
}
